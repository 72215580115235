body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
}

.app-loader-container .app-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -3rem;
  margin-top: -3rem;
}

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(./fonts/SFPRODISPLAYREGULAR.OTF) format('truetype');
}
@font-face {
  font-family: 'SF Pro Display-Italic';
  src: local('SF Pro Display-Italic'), url(./fonts/SFPRODISPLAYLIGHTITALIC.OTF) format('truetype');
}

@font-face {
  font-family: 'SF Pro Display-Bold';
  src: local('SF Pro Display-Bold'), url(./fonts/SFPRODISPLAYBOLD.OTF) format('truetype');
}

.pac-container {
  z-index: 9999
}

/* @font-face {
  font-family: 'SF Pro Display-Black-Italic';
  src: local('SF Pro Display-Black-Italic'), url(./fonts/SFPRODISPLAYBLACKITALIC.OTF) format('truetype');
}
@font-face {
  font-family: 'SF Pro Display-Bold';
  src: local('SF Pro Display-Bold'), url(./fonts/SFPRODISPLAYBOLD.OTF) format('truetype');
}
@font-face {
  font-family: 'SF Pro Display-heavy-Italic';
  src: local('SF Pro Display-heavy-Italic'), url(./fonts/SFPRODISPLAYHEAVYITALIC.OTF) format('truetype');
}

@font-face {
  font-family: 'SF Pro Display-medium';
  src: local('SF Pro Display-medium'), url(./fonts/SFPRODISPLAYMEDIUM.OTF) format('truetype');
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display-regular'), url(./fonts/SFPRODISPLAYREGULAR.OTF) format('truetype');
}
@font-face {
  font-family: 'SF Pro Display-semibold-Italic';
  src: local('SF Pro Display-semibold-Italic'), url(./fonts/SFPRODISPLAYSEMIBOLDITALIC.OTF) format('truetype');
}
@font-face {
  font-family: 'SF Pro Display-thin-Italic';
  src: local('SF Pro Display-thin-Italic'), url(./fonts/SFPRODISPLAYTHINITALIC.OTF) format('truetype');
}
@font-face {
  font-family: 'SF Pro Display-ultralight-Italic';
  src: local('SF Pro Display-ultralight-Italic'), url(./fonts/SFPRODISPLAYULTRALIGHTITALIC.OTF) format('truetype');
} */